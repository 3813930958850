
.share-buttons {
 display: inline-flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: center;
 align-content: stretch;
 align-items: flex-start;
 &-link {
 	@include size(8rem);
 	border-radius: 50%;
 	display: flex;
 	flex-direction: row;
 	flex-wrap: nowrap;
 	justify-content: center;
 	align-content: stretch;
 	align-items: center;
 	background: $green;
  padding: 1rem;
  transition: all .5s $bounce-curve;
  @include desktop {
   	@include size(12rem);
  }
  &:first-of-type {
    margin-right: .5rem;
  }
  &:last-of-type {
    margin-left: .5rem;
  }
  &:hover {
   background: $white;
 	 .share-buttons-link-icon {
  		 fill: $green;
 	 }
 	 .share-buttons-link-txt {
  		 color: $green;
	  } 
	  .share-buttons-link-txt-network {
	    color: $green;
	  }
  }
	&-center {
		width: 100%;
	}
  &-icon {
   @include size(2rem);
   fill: $white;
   margin-bottom: .5rem;
   @include desktop {
     @include size(3rem);
   }
  }
  &-txt {
   font-weight: 900;
   color: $white;
   font-family: $body;
   line-height: 1;
   text-transform: uppercase;
     @include phone {
       font-size: .8rem;
     }   
   &-network {
     display: block;
     @extend %headline5;
     letter-spacing: 0;
     color: $white;
     @include phone {
       font-size: 1.2rem;
     }
   }
  }
 }
}
.share-description {
  color: $white !important;
  a {
    color: $white;
    text-decoration: underline;
  }
}