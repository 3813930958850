.pagination {
	background: $white;
	padding: 1rem;
	@include desktop {
		padding: 2rem;
	}
	&-list {
		max-width: $tablet;
		margin: 0 auto;
		list-style: none;
	    display: flex;
	    flex-direction: row;
	    flex-wrap: nowrap;
	    justify-content: center;
	    align-content: stretch;
	    align-items: center;
	    color: $white;
		li {
			padding: .5rem;
			text-transform: uppercase;
			letter-spacing: .2rem;
			a {
				color: $black;
				border-radius: 2rem;
				padding: .5rem .75rem;
				transition: $slow;
				&:hover {
					background: $green;
				}
			}
		}
		select {
			border: 0;
			background: $green;
			color: $white;
			padding: .5rem 1.5rem;
			outline: none;
			-webkit-appearance: none;
			transition: $slow;
			border-radius: 1rem;
			&:hover {
				background: $green;
			}
			option {
				background: $green;
			}
		}
	}
}