.wall {
	@extend %block-padding;
	padding-bottom: 0;
	@include phone {
		padding-top: 7rem;
	}
	&-headline {
		font-family: $headline;
		font-weight: 900;
	    @extend %headline2;
		line-height: .8;
		margin-bottom: 2rem;
		margin-top: 5rem;
		color: $white;	
		@include tablet {
			margin-top: 3rem;
		}
		@include desktop {
			margin-top: 4rem;
		}
	}
	&-grid {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: stretch;
		margin-bottom: 1rem;
		@include tablet {
			padding: 1rem;
			margin-bottom: 0;
		}
		@include desktop {
			padding: 2rem;
		}
	}
	&-item {
		width: 100%;
		margin: 1%;
		position: relative;
		transition: all .5s $bounce-curve;
		z-index: 50;
		border-radius: 3rem;
		overflow: hidden;
		@include tablet {
			width: calc(50% - 1rem);
			margin: .5rem;
		}
		@include desktop {
			width: calc(33.33% - 1rem);
		}		
		@include md {
			width: calc(25% - 1rem);
		}
		&-aspect {
			@include aspect(1,1);
		}
		&-name {
			width: 100%;
			text-align: left;
			background: linear-gradient(0deg, rgba($black,.7) 0%, rgba($black,0) 100%);
			padding: 2rem;
			@include absolute(left 0 bottom 0);
			color: $white;
			@extend %headline3;
			font-weight: 900;
			z-index: 100;
			@include phone {
				padding: 2rem;
				font-size: 2.5rem;
				letter-spacing: 0;
				line-height: 1;
			}
			div {
				@extend %headline6;
				font-family: $body;
			}
		}
		&-description {
		  font-size: .9rem !important;
		  margin-top: .5rem;
		  line-height: 1.3;
		  font-weight: 400;
		}
		&-photo {
			background-color: $blue;
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
			@include size(100%);
			@include absolute(left 0 top 0);
			z-index: 1;
			transition: all .5s $bounce-curve;
		}
		&:hover {
			box-shadow: $shadow-hover;
			z-index: 100;
			.wall-item {
				z-index: 1000;
				&-photo {
					background-blend-mode: normal;
				}
			}
		}
	}
	&-search {
		background: $green;
		display: inline-block;
		padding: 2rem;
		margin: 0 1rem;
		border-radius: 4rem;
		position: relative;
		z-index: 101;
		@include phone {

		}
		@include tablet {
			padding: 2rem;
		}
		@include desktop {
			min-width: $tablet-wide;
		}
		@include md {
			margin: 0 2rem;
			padding: 4rem;

		}
		@include lg {
			padding: 5rem;
		}
		&-form {
			max-width: $tablet;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: stretch;
		}
		&-input {
			width: 100%;
			border: 0;
			padding: 1rem .75rem 1rem 1.25rem;
			font-size: 1.2rem;
			border-radius: 2rem 0 0 2rem;
			outline: 0;
			font-weight: 700;
			transition: all .5s $slow-curve;
			@include phone {
				font-size: 1rem;
			}
			@include placeholder {
				opacity: 1;
				color: $darkblue;
			}
			&:hover {
				box-shadow: inset 0 0 0 2px $green;
				color: $green;
				@include placeholder {
					color: $green;
				}
			}
			&:focus {
				box-shadow: inset 0 0 0 3px $blue;
				color: $blue;
				@include placeholder {
					color: $blue;
				}
			}
	  }
		&-label {
			@include screen-reader-text;
		}
		&-button {
		    background: $blue;
			font-weight: 900;
			color: $white;
		    @extend %headline5;
		    padding: 1rem 1rem;
		    border: 0;
		    outline: 0;
		    cursor: pointer;
			text-transform: uppercase;
		    border-radius: 0 2rem 2rem 0;
		    transition: all .5s $bounce-curve;
			@include tablet {
				padding: 1rem 2rem;
			}
		    &:hover {
				background: $darkblue;
		    }
	  }
	}
}

.search-results {
	background: transparent;
	padding: 5rem 1rem 1rem;
	position: relative;
	z-index: 101;
	margin-top: 2rem;
	@include tablet {
		padding: 5rem 2rem 2rem;
	}
	&-headline {
		color: $darkgreen;
		@extend %headline4;
		@include desktop {
			margin-bottom: 1rem;
		}
	}
	&-clear {
	    background: $green;
	    font-family: $headline;
		font-weight: 900;
		color: $white;
		text-transform: uppercase;
	    @extend %headline6;
	    padding: 1rem 1rem;
	    border: 0;
	    outline: 0;
	    cursor: pointer;
	    border-radius: 1rem;
	    transition: all .5s $bounce-curve;
		@include tablet {
			padding: 1rem 1.25rem;
		}
	    &:hover {
			background: $darkblue;
	    }
	}
}
.expanded-form {
	.wall {
		&-headline {
			margin-top: 0;
			padding-bottom: 0;
		}
	}
	.search-results {
		padding: 2rem 1rem;
		@include tablet {
			padding: 2rem 2rem;
			padding-bottom: 0;
		}
	}
}