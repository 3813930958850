@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&display=swap");
/* Colors */
.share.expanded, .share-post-submit, .wall {
  padding: 2rem 1rem; }
  @media (min-width: 48em) {
    .share.expanded, .share-post-submit, .wall {
      padding: 4rem 2rem; } }
  @media (min-width: 81.25em) {
    .share.expanded, .share-post-submit, .wall {
      padding: 6rem 2rem; } }
  @media (min-width: 100em) {
    .share.expanded, .share-post-submit, .wall {
      padding: 7rem 2rem; } }
  @media (min-width: 112.5em) {
    .share.expanded, .share-post-submit, .wall {
      padding: 8rem 2rem; } }

.share-submit, .wall-headline {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  line-height: 1.1; }
  @media (min-width: 48em) {
    .share-submit, .wall-headline {
      font-size: 1.8rem; } }
  @media (min-width: 81.25em) {
    .share-submit, .wall-headline {
      font-size: 2.1rem; } }
  @media (min-width: 100em) {
    .share-submit, .wall-headline {
      font-size: 2.4rem; } }
  @media (min-width: 112.5em) {
    .share-submit, .wall-headline {
      font-size: 2.7rem; } }

.share-headline, .wall-item-name {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem; }
  @media (min-width: 48em) {
    .share-headline, .wall-item-name {
      font-size: 1.6rem; } }
  @media (min-width: 81.25em) {
    .share-headline, .wall-item-name {
      font-size: 1.7rem; } }
  @media (min-width: 100em) {
    .share-headline, .wall-item-name {
      font-size: 1.9rem; } }
  @media (min-width: 112.5em) {
    .share-headline, .wall-item-name {
      font-size: 2.3rem; } }

.search-results-headline {
  font-family: "Montserrat", sans-serif;
  font-size: 1.45rem; }
  @media (min-width: 48em) {
    .search-results-headline {
      font-size: 1.55rem; } }
  @media (min-width: 81.25em) {
    .search-results-headline {
      font-size: 1.65rem; } }
  @media (min-width: 100em) {
    .search-results-headline {
      font-size: 1.75rem; } }
  @media (min-width: 112.5em) {
    .search-results-headline {
      font-size: 1.9rem; } }

.wall-search-button, .share-buttons-link-txt-network {
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem; }
  @media (min-width: 81.25em) {
    .wall-search-button, .share-buttons-link-txt-network {
      font-size: 1.35rem; } }
  @media (min-width: 100em) {
    .wall-search-button, .share-buttons-link-txt-network {
      font-size: 1.4rem; } }
  @media (min-width: 112.5em) {
    .wall-search-button, .share-buttons-link-txt-network {
      font-size: 1.5rem; } }

.wall-item-name div, .search-results-clear {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem; }
  @media (min-width: 48em) {
    .wall-item-name div, .search-results-clear {
      font-size: 1.1rem; } }
  @media (min-width: 81.25em) {
    .wall-item-name div, .search-results-clear {
      font-size: 1.15rem; } }
  @media (min-width: 100em) {
    .wall-item-name div, .search-results-clear {
      font-size: 1.2rem; } }
  @media (min-width: 112.5em) {
    .wall-item-name div, .search-results-clear {
      font-size: 1.25rem; } }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: border-box; }

input[type=text], textarea, input[type=tel], input[type=email], input[type=password], input[type=number], input[type=url], input[type=submit], button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

#content[tabindex="-1"]:focus {
  outline: 0; }

address, dl {
  font-style: normal; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body, p, h1, h2, h3, h4, h5, h6, ul, ol, pre, figure {
  margin: 0; }

input[type="button" i], input[type="submit" i], input[type="reset" i], input[type="file" i]::-webkit-file-upload-button, button {
  padding: 0; }

main {
  display: block; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent;
  text-decoration: none; }

a:focus {
  outline: none; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: 700; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

[hidden] {
  display: none; }

.hero {
  position: relative;
  padding: 1rem; }
  @media (min-width: 48em) {
    .hero {
      padding: 1.5rem; } }
  @media (min-width: 81.25em) {
    .hero {
      padding: 2rem; } }
  .hero-wrap {
    max-width: 64em;
    margin: 0 auto;
    position: relative;
    z-index: 1000; }
    .hero-wrap-contents {
      position: relative;
      z-index: 1001; }
  .hero-top {
    max-width: 112.5em;
    margin: 0 auto;
    position: relative;
    margin-bottom: 1rem; }
    @media (min-width: 48em) {
      .hero-top {
        margin-bottom: 3rem; } }
    @media (min-width: 81.25em) {
      .hero-top {
        margin-bottom: 4rem; } }
    .hero-top-img {
      max-width: 100%;
      vertical-align: bottom; }
  .hero-logo {
    max-width: 7rem;
    display: table;
    margin: 0 auto 2rem;
    position: relative;
    z-index: 101; }
    @media (min-width: 48em) {
      .hero-logo {
        max-width: 8rem; } }
    @media (min-width: 100em) {
      .hero-logo {
        max-width: 8rem; } }
    @media (min-width: 81.25em) {
      .hero-logo {
        max-width: 10rem; } }
    .hero-logo-svg {
      width: 100%;
      height: auto;
      fill: #05ad52; }
  .hero-headline {
    position: relative;
    line-height: .8;
    font-size: 10vw;
    font-weight: 900;
    color: #1d548c;
    text-transform: uppercase; }
    @media (min-width: 48em) {
      .hero-headline {
        font-size: 8vw;
        line-height: .8; } }
    @media (min-width: 64em) {
      .hero-headline {
        font-size: 2.25vw;
        margin-bottom: 1rem; } }
    @media (min-width: 81.25em) {
      .hero-headline {
        font-size: 2.5vw;
        line-height: .8;
        margin-bottom: 1.5rem; } }
    @media (min-width: 100em) {
      .hero-headline {
        font-size: 3vw; } }
    .hero-headline strong {
      display: block; }
    .hero-headline-small {
      font-family: "Montserrat", sans-serif;
      display: table;
      font-weight: 900;
      font-size: 95%;
      font-style: normal;
      text-transform: uppercase;
      color: #05ad52;
      margin: 0 auto;
      position: relative; }
      @media (min-width: 48em) {
        .hero-headline-small {
          font-size: 65%; } }
      @media (min-width: 64em) {
        .hero-headline-small {
          font-size: 165%; } }
      @media (min-width: 81.25em) {
        .hero-headline-small {
          font-size: 185%; } }
      @media (min-width: 100em) {
        .hero-headline-small {
          font-size: 125%; } }
      @media (min-width: 112.5em) {
        .hero-headline-small {
          font-size: 115%; } }
    .hero-headline-large {
      font-family: "Montserrat", sans-serif;
      display: block;
      transform: scaleX(0.8);
      font-weight: 700;
      font-size: 27vw;
      text-transform: uppercase;
      color: #006633;
      background: linear-gradient(45deg, #05ad52, #006633);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
      @media (min-width: 48em) {
        .hero-headline-large {
          font-size: 15vw; } }
      @media (min-width: 64em) {
        .hero-headline-large {
          font-size: 12vw; } }
      @media (min-width: 81.25em) {
        .hero-headline-large {
          font-size: 15vw; } }
      @media (min-width: 100em) {
        .hero-headline-large {
          font-size: 12vw; } }
      @media (min-width: 112.5em) {
        .hero-headline-large {
          font-size: 10vw; } }
  .hero-description {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    line-height: 1.4;
    margin-bottom: 4rem;
    text-align: center;
    color: #505050;
    padding: 1rem; }
    @media (min-width: 48em) {
      .hero-description {
        text-align: center;
        margin-bottom: 4rem; } }
    @media (min-width: 81.25em) {
      .hero-description {
        margin-bottom: 4rem;
        padding: 0 2rem 2rem;
        font-size: 1.2rem; } }
    @media (min-width: 100em) {
      .hero-description {
        margin-bottom: 4rem; } }
    @media (min-width: 112.5em) {
      .hero-description {
        margin-bottom: 5rem; } }
    .hero-description p {
      margin-bottom: 1.5rem; }
    .hero-description a {
      color: #05ad52;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
      .hero-description a:hover {
        color: #1d548c; }
  .hero-img {
    width: 100%;
    height: auto;
    z-index: 99; }
    .hero-img-container {
      width: 100%;
      position: relative;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }

.share {
  background-color: #006633;
  position: relative;
  max-width: 64em;
  z-index: 50;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89);
  margin: 0 auto;
  border-radius: 4rem;
  box-shadow: 0px 0.2rem 2rem 0 rgba(0, 0, 0, 0.15); }
  .share.expanded {
    max-height: 9000px; }
    @media (max-width: 64em) {
      .share.expanded {
        padding-top: 3rem;
        padding-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 48em) {
      .share.expanded {
        padding: 4rem; } }
  .share-button {
    background: #1d548c;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    outline: 0;
    width: 10rem;
    height: 10rem;
    box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.5, 1.65, 0.4, 0.8);
    z-index: 100;
    text-transform: uppercase; }
    @media (min-width: 48em) {
      .share-button {
        width: 10rem;
        height: 10rem; } }
    .share-button-aspect {
      padding-top: 100%; }
    .share-button-positioner {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      cursor: pointer;
      font-size: 1.7rem;
      font-weight: 900;
      line-height: .95; }
      @media (max-width: 64em) {
        .share-button-positioner {
          font-size: 2.5rem; } }
    .share-button-break {
      font-style: normal;
      display: block;
      font-family: "Montserrat", sans-serif;
      font-size: 55%; }
      @media (min-width: 81.25em) {
        .share-button-break {
          font-size: 90%; } }
    .share-button-close {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8);
      opacity: 1;
      z-index: 100;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0; }
      .share-button-close-svg {
        transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8);
        width: 100%;
        fill: #fff; }
    .share-button:hover {
      transform: translate(-50%, -50%) scale(1.15); }
    .share-button.expanded {
      width: 4rem;
      height: 4rem; }
      @media (min-width: 81.25em) {
        .share-button.expanded {
          width: 6rem;
          height: 6rem; } }
      .share-button.expanded .share-button-aspect {
        opacity: 0; }
      .share-button.expanded .share-button-close {
        transform: translate(-50%, -50%) scale(100%);
        opacity: 1; }
  .share-headline {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #fff;
    line-height: .8;
    text-transform: uppercase;
    margin-bottom: 2rem; }
  .share-description {
    color: #505050;
    max-width: 48em;
    margin: 0 auto 2rem;
    font-size: 1.2rem;
    line-height: 1.4; }
  .share-form {
    max-width: 48em;
    margin: 1rem auto 0; }
    @media (min-width: 48em) {
      .share-form {
        margin-top: 2rem; } }
    @media (min-width: 81.25em) {
      .share-form {
        margin-top: 3rem; } }
  .share-grouping {
    margin: 3rem auto 1rem; }
    @media (min-width: 48em) {
      .share-grouping {
        margin: 2rem auto 1rem; } }
    @media (min-width: 81.25em) {
      .share-grouping {
        margin: 3rem auto 1rem; } }
  .share-fields {
    width: 100%; }
    @media (min-width: 48em) {
      .share-fields {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch; } }
  .share-field {
    padding: .5rem;
    flex: 1; }
  .share-label {
    display: block;
    text-align: left;
    padding: .25rem 0 .25rem .5rem;
    cursor: pointer;
    color: #fff;
    position: relative; }
    .share-label.required:after {
      position: absolute;
      top: 100%;
      right: 0;
      transform: translateY(-50%);
      background: #d60000;
      padding: .25rem .4rem;
      font-size: .7rem;
      color: #fff;
      display: inline-block;
      z-index: 100;
      content: "Required";
      text-transform: uppercase;
      border-radius: .5rem; }
  .share-input {
    background: #fff;
    width: 100%;
    border: 0;
    padding: 1.25rem 1.25rem;
    font-size: 1.1rem;
    line-height: 1;
    outline: 0;
    border-radius: 2rem;
    font-weight: 700;
    color: #05ad52;
    transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89);
    box-shadow: inset 0 0 0 2px #fff; }
    .share-input.placeholder {
      opacity: 1;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
    .share-input:-moz-placeholder {
      opacity: 1;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
    .share-input::-moz-placeholder {
      opacity: 1;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
    .share-input:-ms-input-placeholder {
      opacity: 1;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
    .share-input::-webkit-input-placeholder {
      opacity: 1;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
    .share-input.textarea {
      height: 10rem; }
    .share-input:hover {
      background-color: #05ad52;
      box-shadow: inset 0 0 0 3px #05ad52;
      color: #fff; }
      .share-input:hover.placeholder {
        color: #fff; }
      .share-input:hover:-moz-placeholder {
        color: #fff; }
      .share-input:hover::-moz-placeholder {
        color: #fff; }
      .share-input:hover:-ms-input-placeholder {
        color: #fff; }
      .share-input:hover::-webkit-input-placeholder {
        color: #fff; }
    .share-input:focus {
      background-color: #fff !important;
      box-shadow: inset 0 0 0 3px #05ad52;
      color: #05ad52; }
      .share-input:focus.placeholder {
        color: #05ad52; }
      .share-input:focus:-moz-placeholder {
        color: #05ad52; }
      .share-input:focus::-moz-placeholder {
        color: #05ad52; }
      .share-input:focus:-ms-input-placeholder {
        color: #05ad52; }
      .share-input:focus::-webkit-input-placeholder {
        color: #05ad52; }
  .share-radios {
    width: 100%; }
    @media (min-width: 48em) {
      .share-radios {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch; } }
  .share-radio {
    margin: 1rem;
    position: relative;
    max-width: 20rem; }
    @media (min-width: 48em) {
      .share-radio {
        margin: 0 auto;
        flex: 1; } }
    .share-radio-input {
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      word-wrap: normal !important;
      visibility: hidden; }
      .share-radio-input:focus {
        background: #1d548c;
        color: #fff;
        clip: auto !important;
        display: inline-block;
        font-size: 1rem;
        height: auto;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100000;
        line-height: normal;
        padding: .5rem;
        width: auto; }
      .share-radio-input:checked ~ .share-radio-label {
        box-shadow: inset 0 0 0 4px #006633; }
        .share-radio-input:checked ~ .share-radio-label .share-radio-check {
          background: #05ad52; }
          .share-radio-input:checked ~ .share-radio-label .share-radio-check-svg {
            width: 70%;
            opacity: 1; }
    .share-radio-label {
      background: #fff;
      padding-top: 100%;
      border-radius: .5rem;
      text-transform: uppercase;
      display: block;
      color: #fff;
      width: 100%;
      border-radius: 3rem;
      transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8);
      cursor: pointer;
      border: 2px solid #000033; }
      .share-radio-label:hover {
        background: #006633 !important;
        box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.3); }
        .share-radio-label:hover .share-radio-avatar {
          background-color: #05ad52; }
          .share-radio-label:hover .share-radio-avatar-upload-svg {
            fill: #fff; }
          .share-radio-label:hover .share-radio-avatar-upload-label {
            color: #fff; }
    .share-radio-avatar {
      background: #fff;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
      border-radius: 1rem;
      border: 2px solid #000033; }
      .share-radio-avatar-upload-svg {
        width: 50%;
        fill: #05ad52;
        transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
      .share-radio-avatar-upload-label {
        color: #006633;
        display: block;
        margin-top: .25rem;
        transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
      .share-radio-avatar-uploaded {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
        .share-radio-avatar-uploaded-delete {
          background: #05ad52;
          width: 2rem;
          height: 2rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-30%, -30%);
          border-radius: 50%;
          z-index: 100;
          cursor: pointer;
          transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
          .share-radio-avatar-uploaded-delete:hover {
            background: #fff;
            transform: translate(-30%, -30%), scale(1.1); }
            .share-radio-avatar-uploaded-delete:hover .share-radio-avatar-uploaded-delete-svg {
              fill: #1d548c; }
          .share-radio-avatar-uploaded-delete-svg {
            fill: #fff;
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
    .share-radio-check {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 30%);
      width: 3rem;
      height: 3rem;
      background: #d0d0d0;
      box-shadow: 0px 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.4);
      border-radius: 50%; }
      .share-radio-check-svg {
        width: 0%;
        opacity: 0;
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
  .share-submit {
    background: #1d548c;
    font-weight: 900;
    color: #fff;
    padding: 1.15rem 2rem 1.2rem;
    border-radius: 3rem;
    border: 0;
    outline: 0;
    text-transform: uppercase;
    transform: translateX(0.9);
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
    .share-submit-container {
      padding: 2rem 0; }
      @media (min-width: 81.25em) {
        .share-submit-container {
          padding: 5rem 0 4rem; } }
    .share-submit:hover {
      background: #1d548c;
      transform: scale(1.1); }
    .share-submit-change {
      display: table;
      padding: 1rem 2rem;
      margin: 0 auto 1rem;
      color: #fff;
      font-weight: 900;
      border-radius: 2rem;
      transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
      .share-submit-change:hover {
        background: #006633; }
  .share-disclaimer {
    color: #fff;
    font-size: 90%; }
    .share-disclaimer a {
      color: #fff;
      border-bottom: 1px solid #05ad52;
      transition: all 0.3s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
      .share-disclaimer a:hover {
        color: #05ad52; }
  .share-post-submit {
    padding-bottom: 0; }
    @media (max-width: 64em) {
      .share-post-submit {
        padding-top: 8rem; } }
    @media (min-width: 48em) {
      .share-post-submit {
        padding-top: 4rem; } }

.photo-required {
  display: table;
  margin: -1rem auto 2rem;
  border: 1px solid #000033;
  background: #d60000;
  color: #fff;
  border-radius: .5rem;
  padding: .5rem 1rem; }

.wall {
  padding-bottom: 0; }
  @media (max-width: 64em) {
    .wall {
      padding-top: 7rem; } }
  .wall-headline {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    line-height: .8;
    margin-bottom: 2rem;
    margin-top: 5rem;
    color: #fff; }
    @media (min-width: 48em) {
      .wall-headline {
        margin-top: 3rem; } }
    @media (min-width: 81.25em) {
      .wall-headline {
        margin-top: 4rem; } }
  .wall-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    margin-bottom: 1rem; }
    @media (min-width: 48em) {
      .wall-grid {
        padding: 1rem;
        margin-bottom: 0; } }
    @media (min-width: 81.25em) {
      .wall-grid {
        padding: 2rem; } }
  .wall-item {
    width: 100%;
    margin: 1%;
    position: relative;
    transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8);
    z-index: 50;
    border-radius: 3rem;
    overflow: hidden; }
    @media (min-width: 48em) {
      .wall-item {
        width: calc(50% - 1rem);
        margin: .5rem; } }
    @media (min-width: 81.25em) {
      .wall-item {
        width: calc(33.33% - 1rem); } }
    @media (min-width: 100em) {
      .wall-item {
        width: calc(25% - 1rem); } }
    .wall-item-aspect {
      padding-top: 100%; }
    .wall-item-name {
      width: 100%;
      text-align: left;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
      padding: 2rem;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      font-weight: 900;
      z-index: 100; }
      @media (max-width: 64em) {
        .wall-item-name {
          padding: 2rem;
          font-size: 2.5rem;
          letter-spacing: 0;
          line-height: 1; } }
      .wall-item-name div {
        font-family: "Montserrat", sans-serif; }
    .wall-item-description {
      font-size: .9rem !important;
      margin-top: .5rem;
      line-height: 1.3;
      font-weight: 400; }
    .wall-item-photo {
      background-color: #1d548c;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
    .wall-item:hover {
      box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.3);
      z-index: 100; }
      .wall-item:hover .wall-item {
        z-index: 1000; }
        .wall-item:hover .wall-item-photo {
          background-blend-mode: normal; }
  .wall-search {
    background: #05ad52;
    display: inline-block;
    padding: 2rem;
    margin: 0 1rem;
    border-radius: 4rem;
    position: relative;
    z-index: 101; }
    @media (min-width: 48em) {
      .wall-search {
        padding: 2rem; } }
    @media (min-width: 81.25em) {
      .wall-search {
        min-width: 64em; } }
    @media (min-width: 100em) {
      .wall-search {
        margin: 0 2rem;
        padding: 4rem; } }
    @media (min-width: 112.5em) {
      .wall-search {
        padding: 5rem; } }
    .wall-search-form {
      max-width: 48em;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch; }
    .wall-search-input {
      width: 100%;
      border: 0;
      padding: 1rem .75rem 1rem 1.25rem;
      font-size: 1.2rem;
      border-radius: 2rem 0 0 2rem;
      outline: 0;
      font-weight: 700;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
      @media (max-width: 64em) {
        .wall-search-input {
          font-size: 1rem; } }
      .wall-search-input.placeholder {
        opacity: 1;
        color: #000033; }
      .wall-search-input:-moz-placeholder {
        opacity: 1;
        color: #000033; }
      .wall-search-input::-moz-placeholder {
        opacity: 1;
        color: #000033; }
      .wall-search-input:-ms-input-placeholder {
        opacity: 1;
        color: #000033; }
      .wall-search-input::-webkit-input-placeholder {
        opacity: 1;
        color: #000033; }
      .wall-search-input:hover {
        box-shadow: inset 0 0 0 2px #05ad52;
        color: #05ad52; }
        .wall-search-input:hover.placeholder {
          color: #05ad52; }
        .wall-search-input:hover:-moz-placeholder {
          color: #05ad52; }
        .wall-search-input:hover::-moz-placeholder {
          color: #05ad52; }
        .wall-search-input:hover:-ms-input-placeholder {
          color: #05ad52; }
        .wall-search-input:hover::-webkit-input-placeholder {
          color: #05ad52; }
      .wall-search-input:focus {
        box-shadow: inset 0 0 0 3px #1d548c;
        color: #1d548c; }
        .wall-search-input:focus.placeholder {
          color: #1d548c; }
        .wall-search-input:focus:-moz-placeholder {
          color: #1d548c; }
        .wall-search-input:focus::-moz-placeholder {
          color: #1d548c; }
        .wall-search-input:focus:-ms-input-placeholder {
          color: #1d548c; }
        .wall-search-input:focus::-webkit-input-placeholder {
          color: #1d548c; }
    .wall-search-label {
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      word-wrap: normal !important; }
      .wall-search-label:focus {
        background: #1d548c;
        color: #fff;
        clip: auto !important;
        display: inline-block;
        font-size: 1rem;
        height: auto;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100000;
        line-height: normal;
        padding: .5rem;
        width: auto; }
    .wall-search-button {
      background: #1d548c;
      font-weight: 900;
      color: #fff;
      padding: 1rem 1rem;
      border: 0;
      outline: 0;
      cursor: pointer;
      text-transform: uppercase;
      border-radius: 0 2rem 2rem 0;
      transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
      @media (min-width: 48em) {
        .wall-search-button {
          padding: 1rem 2rem; } }
      .wall-search-button:hover {
        background: #000033; }

.search-results {
  background: transparent;
  padding: 5rem 1rem 1rem;
  position: relative;
  z-index: 101;
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .search-results {
      padding: 5rem 2rem 2rem; } }
  .search-results-headline {
    color: #006633; }
    @media (min-width: 81.25em) {
      .search-results-headline {
        margin-bottom: 1rem; } }
  .search-results-clear {
    background: #05ad52;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    padding: 1rem 1rem;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 1rem;
    transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
    @media (min-width: 48em) {
      .search-results-clear {
        padding: 1rem 1.25rem; } }
    .search-results-clear:hover {
      background: #000033; }

.expanded-form .wall-headline {
  margin-top: 0;
  padding-bottom: 0; }

.expanded-form .search-results {
  padding: 2rem 1rem; }
  @media (min-width: 48em) {
    .expanded-form .search-results {
      padding: 2rem 2rem;
      padding-bottom: 0; } }

.pagination {
  background: #fff;
  padding: 1rem; }
  @media (min-width: 81.25em) {
    .pagination {
      padding: 2rem; } }
  .pagination-list {
    max-width: 48em;
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    color: #fff; }
    .pagination-list li {
      padding: .5rem;
      text-transform: uppercase;
      letter-spacing: .2rem; }
      .pagination-list li a {
        color: #000;
        border-radius: 2rem;
        padding: .5rem .75rem;
        transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
        .pagination-list li a:hover {
          background: #05ad52; }
    .pagination-list select {
      border: 0;
      background: #05ad52;
      color: #fff;
      padding: .5rem 1.5rem;
      outline: none;
      -webkit-appearance: none;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89);
      border-radius: 1rem; }
      .pagination-list select:hover {
        background: #05ad52; }
      .pagination-list select option {
        background: #05ad52; }

.footer {
  padding: 2rem;
  color: #505050; }
  @media (min-width: 48em) {
    .footer {
      padding: 3rem; } }
  @media (min-width: 100em) {
    .footer {
      padding: 4rem; } }
  .footer-logo {
    width: 10rem;
    max-width: 40%;
    display: block;
    margin: 0 auto; }
    .footer-logo-svg {
      fill: #05ad52;
      width: 100%; }
  .footer-disclaimer {
    margin-top: 2rem; }
    .footer-disclaimer a {
      display: table;
      color: #05ad52;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89);
      margin: .5rem auto; }
      .footer-disclaimer a:hover {
        color: #1d548c; }

.share-buttons {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start; }
  .share-buttons-link {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    background: #05ad52;
    padding: 1rem;
    transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
    @media (min-width: 81.25em) {
      .share-buttons-link {
        width: 12rem;
        height: 12rem; } }
    .share-buttons-link:first-of-type {
      margin-right: .5rem; }
    .share-buttons-link:last-of-type {
      margin-left: .5rem; }
    .share-buttons-link:hover {
      background: #fff; }
      .share-buttons-link:hover .share-buttons-link-icon {
        fill: #05ad52; }
      .share-buttons-link:hover .share-buttons-link-txt {
        color: #05ad52; }
      .share-buttons-link:hover .share-buttons-link-txt-network {
        color: #05ad52; }
    .share-buttons-link-center {
      width: 100%; }
    .share-buttons-link-icon {
      width: 2rem;
      height: 2rem;
      fill: #fff;
      margin-bottom: .5rem; }
      @media (min-width: 81.25em) {
        .share-buttons-link-icon {
          width: 3rem;
          height: 3rem; } }
    .share-buttons-link-txt {
      font-weight: 900;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      line-height: 1;
      text-transform: uppercase; }
      @media (max-width: 64em) {
        .share-buttons-link-txt {
          font-size: .8rem; } }
      .share-buttons-link-txt-network {
        display: block;
        letter-spacing: 0;
        color: #fff; }
        @media (max-width: 64em) {
          .share-buttons-link-txt-network {
            font-size: 1.2rem; } }

.share-description {
  color: #fff !important; }
  .share-description a {
    color: #fff;
    text-decoration: underline; }

.admin-item {
  max-width: 48em;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89); }
  .admin-item:hover {
    box-shadow: 0 0.5rem 1.25rem 0 rgba(0, 0, 0, 0.2); }
  .admin-item:nth-child(even) {
    background-color: #f4f4f4; }
  .admin-item-photo {
    width: 40%;
    padding: 1rem; }
    .admin-item-photo-img {
      width: 100%; }
  .admin-item-details {
    width: 60%;
    padding: 2rem; }
  .admin-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center; }
    .admin-item-row-label {
      padding: .25rem;
      width: 20%;
      text-align: left;
      font-weight: 900;
      color: #006633; }
    .admin-item-row-value {
      padding: .35rem;
      flex: 1;
      text-align: left; }
    .admin-item-row select {
      padding: .25rem;
      border: 1px #d0d0d0 solid;
      width: 100%;
      outline: 0;
      transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89);
      color: #000033; }
      .admin-item-row select:hover {
        background: #000033;
        color: #fff; }

.admin-filters {
  display: inline-flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  margin: 3rem auto; }
  .admin-filters-label {
    padding-right: 1rem;
    width: 25%;
    text-align: left;
    font-weight: 900;
    color: #006633;
    white-space: nowrap; }
  .admin-filters button {
    background: #1d548c;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 1rem;
    text-transform: uppercase;
    padding: .5rem 1rem;
    margin: 0 .25rem;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 2rem;
    transition: all 0.5s cubic-bezier(0.5, 1.65, 0.4, 0.8); }
    .admin-filters button:hover {
      background: #05ad52;
      transform: scale(1.05); }

html {
  background: #fff;
  position: relative;
  scroll-behavior: smooth; }

body {
  background: center top url(/img/bg.jpg) no-repeat;
  background-size: 100% auto;
  font-family: "Montserrat", sans-serif;
  color: #000;
  text-align: center; }
  body.mobile-menu-active {
    overflow: hidden; }

.screen-reader {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important; }
  .screen-reader:focus {
    background: #1d548c;
    color: #fff;
    clip: auto !important;
    display: inline-block;
    font-size: 1rem;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100000;
    line-height: normal;
    padding: .5rem;
    width: auto; }

.skip-to-main {
  background: #fff;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1000000;
  padding: 1rem;
  color: #1d548c;
  text-transform: uppercase;
  display: block;
  transition: all 0.5s cubic-bezier(0.57, 0.2, 0.21, 0.89);
  transform: translateY(calc(-100% - .5rem));
  border-radius: 2rem; }
  .skip-to-main:focus, .skip-to-main:focus-within {
    transform: translateY(0);
    outline: 2px solid #1d548c; }

#main_content {
  position: relative; }

#preview-photo {
  height: 240px; }

#admin-app {
  background: #fff; }

sub {
  bottom: auto;
  position: relative; }
