@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&display=swap');
@import "partials/global/globals";
@import "partials/global/normalize";
@import "partials/elements/hero";
@import "partials/elements/form";
@import "partials/elements/wall";
@import "partials/elements/_pagination";
@import "partials/elements/footer";
@import "partials/elements/social";
@import "partials/elements/admin";

html {
	background: $white;	
	position: relative;
	scroll-behavior: smooth;
}
body {
	background: center top url(/img/bg.jpg) no-repeat;
	background-size: 100% auto;
	font-family: $body;
	color: $text;
	text-align: center;
	&.mobile-menu-active {
		overflow: hidden;
	}
}
.screen-reader {
	@include screen-reader-text;
}
.skip-to-main {
	background: $white;
	@include fixed(left .5rem top .5rem);
	z-index: 1000000;
	padding: 1rem;
	color: $blue;
	text-transform: uppercase;
	display: block;
	transition: $slow;
	transform: translateY(calc(-100% - .5rem));
	border-radius: 2rem;
	&:focus, &:focus-within {
		transform: translateY(0);
		outline: 2px solid $blue;
	}
}
#main_content {
	position: relative;
}
#preview-photo {
	height: 240px;
}
#admin-app {
	background: $white;
}

sub {
	bottom: auto;
	position: relative;
}