.share {
  background-color: $darkgreen;
  position: relative;
  max-width: $tablet-wide;
  z-index: 50;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
  transition: all .5s $slow-curve;  
  margin: 0 auto;
  border-radius: 4rem;
  box-shadow: 0px .2rem 2rem 0 rgba(0,0,0,.15);
  &.expanded {
    max-height: 9000px;
    @extend %block-padding;
    @include phone {
      padding-top: 3rem;
      padding-bottom: 2rem;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @include tablet {
      padding: 4rem;
    }    
  }
  &-button {
		background: $blue;
    border-radius: 50%;
    @include absolute(top 0 left 50%);
    transform: translate(-50%, -50%);
    border: 0;
    outline: 0;
    @include size(10rem);
    box-shadow: 0 .3rem 2rem 0 rgba($black,.3);
    cursor: pointer;
    transition: all .3s $bounce-curve;
    z-index: 100;
    text-transform: uppercase;
    @include tablet {
      @include size(10rem);
    }
    &-aspect {
      @include aspect(1,1);
    }
    &-positioner {
      width: 100%;
      @include absolute(top 50% left 50%);
      transform: translate(-50%, -50%);
      color: $white;
      cursor: pointer;
      font-size: 1.7rem;
      font-weight: 900;
      line-height: .95;
      @include phone {
        font-size: 2.5rem;
      }     
    }
    &-break {
      font-style: normal;
      display: block;
      font-family: $body;
      font-size: 55%;
      @include desktop {
        font-size: 90%;
      }
    }
    &-close {
      @include size(100%);
      @include absolute(top 50% left 50%);
      transition: all .5s $bounce-curve;
      opacity: 1;
      z-index: 100;
      transform: translate(-50%, -50%) scale(.5);
      opacity: 0;
      &-svg {
        transition: all .5s $bounce-curve;
        width: 100%;
        fill: $white;
      }
    }
    &:hover {
      transform: translate(-50%, -50%) scale(1.15);
    }
    &.expanded {
      @include size(4rem);
      @include desktop {
        @include size(6rem);
      }  
      &:hover {
      }
      .share-button {
        &-aspect {
          opacity: 0;
        }
        &-close {
          transform: translate(-50%, -50%) scale(100%);
          opacity: 1;    
        }
      }
    }
  }
  &-headline {
    font-family: $headline;
		font-weight: 900;
		color: $white;
    @extend %headline3;
		line-height: .8;
		text-transform: uppercase;
		margin-bottom: 2rem;
  }
  &-description {
    color: $gray;
    max-width: $tablet;
    margin: 0 auto 2rem;
    font-size: 1.2rem;
    line-height: 1.4;
  }
  &-form {
    max-width: $tablet;
    margin: 1rem auto 0;
    @include tablet {
      margin-top: 2rem;
    }
    @include desktop {
      margin-top: 3rem;
    }
  }
  &-grouping {
    margin: 3rem auto 1rem;
    @include tablet {
      margin: 2rem auto 1rem;
    }
    @include desktop {
      margin: 3rem auto 1rem;
    }
  }
  &-fields {
    width: 100%;
    @include tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
    }
  }
  &-field {
    padding: .5rem;
    flex: 1;
  }
  &-label {
    display: block;
    text-align: left;
    padding: .25rem 0 .25rem .5rem;
    cursor: pointer;
		color: $white;
    position: relative;
		&.required {
      &:after {
        @include absolute(right 0 top 100%);
        transform: translateY(-50%);
        background: $red;
        padding: .25rem .4rem;
        font-size: .7rem;
        color: $white;
        display: inline-block;
        z-index: 100;
        content: "Required";
        text-transform: uppercase;
        border-radius: .5rem;
      }
		}    
  }
  &-input {
    background: $white;
    width: 100%;
    border: 0;
    padding: 1.25rem 1.25rem;
    font-size: 1.1rem;
    line-height: 1;
    outline: 0;
    border-radius: 2rem;
		font-weight: 700;
    color: $green;
		transition: all .5s $slow-curve;
    box-shadow: inset 0 0 0 2px $white;
		@include placeholder {
			opacity: 1;
			color: $white;
      transition: all .5s $slow-curve;
		}
		&.textarea {
		  height: 10rem;
		}
		&:hover {
      background-color: $green;
      box-shadow: inset 0 0 0 3px $green;
			color: $white;
			@include placeholder {
				color: $white;
			}
		}
		&:focus {
      background-color: $white !important;
			box-shadow: inset 0 0 0 3px $green;
			color: $green;
			@include placeholder {
				color: $green;
			}
		}
  }
  &-radios {
    width: 100%;
    @include tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
    }
  }
  &-radio {
    margin: 1rem;
    position: relative;
    max-width: 20rem;
    @include tablet {
      margin: 0 auto;
      flex: 1;
    }
    &-input {
      @include screen-reader-text;
      visibility: hidden;
      &:checked ~ .share-radio-label {
        box-shadow: inset 0 0 0 4px $darkgreen;
        .share-radio-check {
          background: $green;
          &-svg {
            width: 70%;
            opacity: 1;
          }
        }
      }
    }
    &-label {
      background: $white;
      @include aspect(1,1);
      border-radius: .5rem;
      text-transform: uppercase;
      display: block;
      color: $white;
      width: 100%;
      border-radius: 3rem;
      transition: all .5s $bounce-curve;
      cursor: pointer;
      border: 2px solid $darkblue;
      &:hover {
        background: $darkgreen !important;
        box-shadow: $shadow-hover;
        .share-radio-avatar {
          background-color: $green;
          &-upload {
            &-svg {
              fill: $white;
            }
            &-label {
              color: $white;
            }
          }
        }
      }
    }
    &-avatar {
  		background: $white;
      @include size(100%);
      @include absolute(left 0 bottom 0);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
      border-radius: 1rem;
      border: 2px solid $darkblue;
      &-upload {
        &-svg {
          width: 50%;
          fill: $green;
          transition: all .5s $bounce-curve;
        }
        &-label {
          color: $darkgreen;
          display: block;
          margin-top: .25rem;
          transition: all .5s $bounce-curve;
        }
      }
      &-uploaded {
        border-radius: 2rem;
        @include size(100%);
        @include absolute(left 0 top 0); 
        &-delete {
          background: $green;
          @include size(2rem);
          @include absolute(left 0 top 0); 
          transform: translate(-30%, -30%);
          border-radius: 50%;
          z-index: 100;
          cursor: pointer;
          transition: all .5s $bounce-curve;
          &:hover {
            background: $white;
            transform: translate(-30%, -30%), scale(1.1);
            .share-radio-avatar-uploaded-delete-svg {
              fill: $blue;
            }
          }
          &-svg {
            fill: $white;
            width: 80%;
            @include absolute(left 50% top 50%); 
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    &-check {
      @include absolute(left 50% bottom 0);
      transform: translate(-50%, 30%);
      @include size(3rem);
      background: $lightgray;
      box-shadow: $button-shadow;
      border-radius: 50%;
      &-svg {
        width: 0%;
        opacity: 0;
        fill: $white;
        @include absolute(left 50% top 50%);
        transform: translate(-50%, -50%);
        transition: all .5s $bounce-curve;
      }
    }
  }
  &-submit {
    &-container {
      padding: 2rem 0;
      @include desktop {
        padding: 5rem 0 4rem;  
      }
    }
		background: $blue;
		font-weight: 900;
		color: $white;
    @extend %headline2;
    padding: 1.15rem 2rem 1.2rem;
    border-radius: 3rem;
    border: 0;
    outline: 0;
    text-transform: uppercase;
    transform: translateX(.9);
    cursor: pointer;
    transition: all .5s $bounce-curve;
    &:hover {
      background: $blue;
      transform: scale(1.1);
    }
    &-change {
      display: table;
      padding: 1rem 2rem;
      margin: 0 auto 1rem;
      color: $white;
      font-weight: 900;
      border-radius: 2rem;
      transition: all .5s $bounce-curve;
      &:hover {
        background: $darkgreen;
      }
    }
  }
  &-disclaimer {
    color: $white;
    font-size: 90%;
    a {
      color: $white;
      border-bottom: 1px solid $green;
      transition: all .3s $slow-curve;
      &:hover {
        color: $green;
      }
    }
  }
  &-post-submit {
    @extend %block-padding;
    padding-bottom: 0;
    @include phone {
      padding-top: 8rem;
    }
    @include tablet {
      padding-top: 4rem;
    }    
  }
}


.photo-required {
  display: table;
  margin: -1rem auto 2rem;
  border: 1px solid $darkblue;
  background: $red;
  color: $white;
  border-radius: .5rem;
  padding: .5rem 1rem;
}