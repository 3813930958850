.hero {
	position: relative;
	padding: 1rem;

	@include tablet {
		padding: 1.5rem;
	}

	@include desktop {
		padding: 2rem;
	}

	&-wrap {
		max-width: $tablet-wide;
		margin: 0 auto;
		position: relative;
		z-index: 1000;
		&-contents {
			position: relative;
			z-index: 1001;
		}
	}

	&-top {
		max-width: $desktop-large;
		margin: 0 auto;
		position: relative;
		margin-bottom: 1rem;
		@include tablet {
			margin-bottom: 3rem;
		}

		@include desktop {
			margin-bottom: 4rem;
		}

		&-img {
			max-width: 100%;
			vertical-align: bottom;
		}
	}

	&-logo {
		max-width: 7rem;
		display: table;
		margin: 0 auto 2rem;
		position: relative;
		z-index: 101;
		@include tablet {
			max-width: 8rem;
		}
		@include md {
			max-width: 8rem;
		}
		@include desktop {
			max-width: 10rem;
		}
		&-svg {
			width: 100%;
			height: auto;
			fill: $green;
		}
	}
	&-headline {
		position: relative;
		line-height: .8;
		font-size: 10vw;
		font-weight: 900;
		color: $blue;
		text-transform: uppercase;
		@include tablet {
			font-size: 8vw;
			line-height: .8;
		}
		@include tablet-wide {
			font-size: 2.25vw;
			margin-bottom: 1rem;
		}
		@include desktop {
			font-size: 2.5vw;
			line-height: .8;
			margin-bottom: 1.5rem;
		}
		@include md {
			font-size: 3vw;
		}
		strong {
			display: block;
		}
		&-small {
			font-family: $body;
			display: table;
			font-weight: 900;
			font-size: 95%;
			font-style: normal;
			text-transform: uppercase;
			color: $green;
			margin: 0 auto;
			position: relative;
			@include tablet {
				font-size: 65%;
			}
			@include tablet-wide {
				font-size: 165%;
			}
			@include desktop {
				font-size: 185%;
			}			
			@include md {
				font-size: 125%;
			}
			@include lg {
				font-size: 115%;
			}
		}
		&-large {
			font-family: $headline;
			display: block;
			transform: scaleX(.8);
			font-weight: 700;
			font-size: 27vw;
			text-transform: uppercase;
			color: $darkgreen;
			background: linear-gradient(45deg, $green, $darkgreen);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;	
			@include tablet {
				font-size: 15vw;
			}
			@include tablet-wide {
				font-size: 12vw;
			}
			@include desktop {
				font-size: 15vw;
			}
			@include md {
				font-size: 12vw;
			}
			@include lg {
				font-size: 10vw;
			}
		}
	}
	&-description {
		font-family: $body;
		font-size: 1.1rem;
		line-height: 1.4;
		margin-bottom: 4rem;
		text-align: center;
		color: $gray;
		padding: 1rem;
		@include tablet {
			text-align: center;
			margin-bottom: 4rem;
		}
		@include desktop {
			margin-bottom: 4rem;
			padding: 0 2rem 2rem;
			font-size: 1.2rem;
		}
		@include md {
			margin-bottom: 4rem;
		}
		@include lg {
			margin-bottom: 5rem;
		}
		p {
			margin-bottom: 1.5rem;
		}
		a {
			color: $green;
			transition: $slow;
			&:hover {
				color: $blue;
			}
		}
	}
	&-img {
		&-container {
			width: 100%;
			position: relative;
			@include absolute(left 0 top 0);
			z-index: 1;
		}
		width: 100%;
		height: auto;
		z-index: 99;
	}
}