.footer {
	padding: 2rem;
	color: $gray;
	@include tablet {
		padding: 3rem;
	}
	@include md {
		padding: 4rem;
	}
	&-logo {
		width: 10rem;
		max-width: 40%;
		display: block;
		margin: 0 auto;
		&-svg {
			fill: $green;
			width: 100%;
		}
	}
	&-disclaimer {
		margin-top: 2rem;
		a {
			display: table;
			color: $green;
			transition: $slow;
			margin: .5rem auto;
			&:hover {
				color: $blue;
			}
		}
	}
}