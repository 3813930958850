.admin {
	&-item {
	 max-width: $tablet;
	 margin: 1rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  transition: $slow;
  &:hover {
   box-shadow: $shadow;
  }
  &:nth-child(even) {
   background-color: $lightestgray;
  }
		&-photo {
			width: 40%;
			padding: 1rem;
			&-img {
				width: 100%;
			}
		}
		&-details {
			width: 60%;
			padding: 2rem;
		}
		&-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
			&-label {
				padding: .25rem;
				width: 20%;
				text-align: left;
				font-weight: 900;
				color: $darkgreen;
			}
			&-value {
				padding: .35rem;
				flex: 1;
				text-align: left;
			}
			select {
			 padding: .25rem;
			 border: 1px $lightgray solid;
			 width: 100%;
			 outline: 0;
    transition: $slow;
    color: $darkblue;
    &:hover {
     background: $darkblue;
     color: $white;
    }
			}
		}
	}
	&-filters {
    display: inline-flex;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    margin: 3rem auto;
    &-label {
 				padding-right: 1rem;
 				width: 25%;
 				text-align: left;
 				font-weight: 900;
 				color: $darkgreen;
 				white-space: nowrap;
    }
    button {
     background: $blue;
     font-family: $headline;
   		font-weight: 900;
   		color: $white;
     font-family: $body;
     font-weight: 900;
     font-size: 1rem;
     text-transform: uppercase;
     padding: .5rem 1rem;
     margin: 0 .25rem;
     border: 0;
     outline: 0;
     cursor: pointer;
     border-radius: 2rem;
     transition: all .5s $bounce-curve;
     &:hover {
       background: $green;
       transform: scale(1.05);
     }     
    }
	}
}